var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isLoading)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',[_c('div',{staticClass:"d-flex align-items-center line-height-1 pb-2 "},[_c('i',{staticClass:"text-primary ti ti-news small-gap-right"}),_c('h4',{staticClass:"text-primary mb-0"},[_vm._v("Daftar Berita")])]),_c('div',[_c('b-row',[_c('b-col',{attrs:{"lg":"1"}},[_c('b-form-select',{staticClass:"w-auto mr-1",attrs:{"options":_vm.options},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}})],1),_c('b-col'),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Filter")]),_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('b-button',{staticClass:"d-flex",attrs:{"variant":"primary","to":{
                        name: _vm.checkAuthorizeRole('news-add'),
                      }}},[_c('i',{staticClass:"ti ti-plus small-gap-right"}),_vm._v(" Tambah ")])],1)])],1)],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
                enabled: true,
                externalQuery: _vm.searchTerm,
              },"select-options":{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              },"pagination-options":{
                enabled: true,
                perPage: _vm.pageLength,
              },"line-numbers":true},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'news')?_c('span',{staticClass:"text-nowrap"},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('b-img',{staticClass:"small-image mr-1 border",attrs:{"rounded":"","src":props.row.news.newsImages,"alt":""}})],1)]):(props.column.field === 'newsTitle')?_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(props.row.newsTitle)+" ")]):(props.column.field === 'status')?_c('span',{staticClass:"text-nowrap"},[_c('b-form-group',[_c('v-select',{attrs:{"options":['Published', 'Unpublished']},on:{"input":function($event){return _vm.handlePublishNews(props.row, props.row.status)}},model:{value:(props.row.status),callback:function ($$v) {_vm.$set(props.row, "status", $$v)},expression:"props.row.status"}})],1)],1):(props.column.field === 'action')?_c('span',{staticClass:"d-flex justify-content-start"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"info","size":"sm","to":{
                      name: _vm.checkAuthorizeRole('news-detail'),
                      params: { id: props.row.id },
                    }}},[_c('i',{staticClass:"ti ti-news"})]),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"warning","size":"sm","to":{
                      name: _vm.checkAuthorizeRole('news-edit'),
                      params: { id: props.row.id },
                    }}},[_c('i',{staticClass:"ti ti-pencil"})]),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.handleDelete(props.row)}}},[(_vm.buttonLoadingId !== props.row.id)?_c('i',{staticClass:"ti ti-trash"}):_vm._e(),(_vm.buttonLoadingId === props.row.id)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('b-form-select',{staticClass:"w-auto mr-1",attrs:{"hidden":"","options":_vm.options},on:{"input":function($event){return props.perPageChanged({ currentPerPage: _vm.pageLength })}},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap "},[_vm._v(" Showing "+_vm._s(_vm.pageLength <= props.total ? _vm.pageLength * _vm.currentPage : props.total)+" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) {
                          _vm.currentPage = value
                          props.pageChanged({ currentPage: value })
                        }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}],null,false,4105949584)})],1)])],1)],1)],1):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"d-flex justify-content-center vertical-center"},[_c('div',{staticClass:"text-center mt-4"},[_c('b-spinner',{staticClass:"mr-1",attrs:{"variant":"primary"}}),_c('p',{staticClass:"mt-1"},[_vm._v(" Memuat Berita ")])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }