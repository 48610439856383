<template>
  <div>
    <div v-if="!isLoading">
      <b-row>
        <b-col md="12">
          <b-card>
            <!-- Header -->
            <div class="d-flex align-items-center line-height-1 pb-2 ">
              <i class="text-primary ti ti-news small-gap-right" />
              <h4 class="text-primary mb-0">Daftar Berita</h4>
            </div>

            <!-- Table of Student-->
            <div>
              <b-row>
                <!-- Display Row -->
                <b-col lg="1">
                  <b-form-select
                    class="w-auto mr-1"
                    v-model="pageLength"
                    :options="options"
                  />
                </b-col>

                <!-- Table Filter -->
                <b-col></b-col>
                <b-col lg="4">
                  <b-form-group>
                    <div class="d-flex align-items-center">
                      <label class="mr-1">Filter</label>
                      <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block mr-1"
                      />

                      <!-- add button -->
                      <b-button
                        variant="primary"
                        class="d-flex"
                        :to="{
                          name: checkAuthorizeRole('news-add'),
                        }"
                      >
                        <i class="ti ti-plus small-gap-right" />
                        Tambah
                      </b-button>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Table -->
              <vue-good-table
                :columns="columns"
                :rows="rows"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm,
                }"
                :select-options="{
                  enabled: false,
                  selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                  selectionInfoClass: 'custom-class',
                  selectionText: 'rows selected',
                  clearSelectionText: 'clear',
                  disableSelectInfo: true, // disable the select info panel on top
                  selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: pageLength,
                }"
                :line-numbers="true"
              >
                <template slot="table-row" slot-scope="props">
                  <!-- Customize Column -->

                  <!-- Column: Name -->
                  <span
                    v-if="props.column.field === 'news'"
                    class="text-nowrap"
                  >
                    <div class="d-flex justify-content-start">
                      <b-img
                        class="small-image mr-1 border"
                        rounded=""
                        :src="props.row.news.newsImages"
                        alt=""
                      />
                    </div>
                  </span>

                  <!-- Column: Name -->
                  <span
                    v-else-if="props.column.field === 'newsTitle'"
                    class="font-weight-bolder"
                  >
                    {{ props.row.newsTitle }}
                  </span>

                  <!-- Column: Status -->
                  <span
                    v-else-if="props.column.field === 'status'"
                    class="text-nowrap"
                  >
                    <b-form-group>
                      <v-select
                        v-model="props.row.status"
                        :options="['Published', 'Unpublished']"
                        @input="handlePublishNews(props.row, props.row.status)"
                      />
                    </b-form-group>
                  </span>

                  <!-- Column: Action -->
                  <span
                    v-else-if="props.column.field === 'action'"
                    class="d-flex justify-content-start"
                  >
                    <b-button
                      variant="info"
                      size="sm"
                      class="mr-1"
                      :to="{
                        name: checkAuthorizeRole('news-detail'),
                        params: { id: props.row.id },
                      }"
                    >
                      <i class="ti ti-news" />
                    </b-button>
                    <b-button
                      variant="warning"
                      size="sm"
                      class="mr-1"
                      :to="{
                        name: checkAuthorizeRole('news-edit'),
                        params: { id: props.row.id },
                      }"
                    >
                      <i class="ti ti-pencil" />
                    </b-button>
                    <b-button
                      variant="danger"
                      size="sm"
                      class="mr-1"
                      @click="handleDelete(props.row)"
                    >
                      <i
                        v-if="buttonLoadingId !== props.row.id"
                        class="ti ti-trash"
                      />
                      <b-spinner
                        v-if="buttonLoadingId === props.row.id"
                        small
                      />
                    </b-button>
                  </span>

                  <!-- Column: Common -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>

                <!-- Pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <b-form-select
                        v-model="pageLength"
                        hidden
                        class="w-auto mr-1"
                        :options="options"
                        @input="
                          props.perPageChanged({ currentPerPage: pageLength })
                        "
                      />
                      <span class="text-nowrap ">
                        Showing
                        {{
                          pageLength <= props.total
                            ? pageLength * currentPage
                            : props.total
                        }}
                        of {{ props.total }} entries
                      </span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="
                          (value) => {
                            currentPage = value
                            props.pageChanged({ currentPage: value })
                          }
                        "
                      >
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <div v-if="isLoading" class="d-flex justify-content-center vertical-center">
      <div class="text-center mt-4">
        <b-spinner variant="primary" class="mr-1" />
        <p class="mt-1">
          Memuat Berita
        </p>
      </div>
    </div>
  </div>
</template>

<script>
//  *Component
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BImg,
  BBadge,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// *Utils
import client from '@/libs/http/axios-config'
import { pascalCase, toHumanDate } from '@core/utils/filter'
import { checkAuthorizeRole } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BImg,
    BBadge,
    BSpinner,

    VueGoodTable,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {},
  data() {
    return {
      // State
      isLoading: true,
      buttonLoading: false,
      buttonLoadingId: '',

      // Initial Data
      classroomId: this.$router.currentRoute.params.id,

      // Table option
      searchTerm: '',
      currentPage: 1,
      pageLength: 5,
      options: ['5', '10', '30'],
      columns: [
        {
          label: 'Berita',
          field: 'news',
          width: '10%',
        },
        {
          label: 'Judul',
          field: 'newsTitle',
        },
        {
          label: 'Tanggal',
          field: 'newsDate',
        },
        {
          label: 'Status',
          field: 'status',
          width: '200px',
        },
        {
          label: 'Aksi',
          field: 'action',
          width: '5%',
        },
      ],
      rows: [],
    }
  },
  async mounted() {
    await client
      .get('/news')
      .then((response) => {
        const news = response.data.data

        const listOfNews = news.map((newsItem) => {
          return {
            id: newsItem.id,
            news: {
              newsImages: newsItem.image,
              newsTitle: newsItem.title,
              newsDescription: newsItem.url,
            },
            newsTitle: newsItem.title,
            newsDate: this.toHumanDate(newsItem.created_at.substring(0, 10)),
            status: this.pascalCase(newsItem.status),
          }
        })

        this.rows = listOfNews

        this.isLoading = false
      })
      .catch((error) => {
        console.error(error)
      })
  },
  methods: {
    pascalCase,
    toHumanDate,
    checkAuthorizeRole,
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    getStatus(status) {
      return status == 'published' ? 'success' : 'danger'
    },
    async handlePublishNews(news, status) {
      const data = { status: status.toLowerCase() }
      await client
        .put(`news/${news.id}/action`, data)
        .then(() => {
          console.log('Berita berhasil dipublish')

          this.showToast(
            'success',
            'CheckIcon',
            'Berhasil',
            'Berita berhasil diupdate'
          )
        })
        .catch((error) => {
          this.showToast('error', 'XIcon', 'Gagal', error.response.data.message)
        })
    },

    handleDelete(news) {
      this.$swal({
        title: 'Anda Yakin?',
        text: 'Data yang dihapus tidak dapat dikembalikan',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.buttonLoadingId = news.id
          this.deleteNews(news.id)
        }
      })
    },
    async deleteNews(newsId) {
      await client.delete(`news/${newsId}`).then(() => {
        this.refreshNewsData().then(() => {
          this.buttonLoadingId = ''
          this.showToast(
            'success',
            'CheckIcon',
            'Berhasil',
            'Berita berhasil dihapus'
          )
        })
      })
    },
    async refreshNewsData() {
      await client
        .get('/news')
        .then((response) => {
          const news = response.data.data

          const listOfNews = news.map((newsItem) => {
            return {
              id: newsItem.id,
              news: {
                newsImages: newsItem.image,
                newsTitle: newsItem.title,
                newsDescription: newsItem.url,
              },
              newsTitle: newsItem.title,
              newsDate: this.toHumanDate(newsItem.created_at.substring(0, 10)),
              status: this.pascalCase(newsItem.status),
            }
          })

          this.rows = listOfNews
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.line-numbers {
  padding: 0 1.2em !important;
}

.schedule-filter {
  min-width: 150px !important;
}

.small-image {
  width: 130px;
  height: 100px;
  object-fit: cover;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vs__clear {
  display: none;
}
</style>
